<template>
  <source :srcset="mainSrc" :sizes="sizes" :media="media" :type="imageType" />
</template>
<script setup lang="ts">
const props = defineProps({
  // @deprecated
  sourceUrl: { type: String, required: false, default: '' },
  srcset: { type: String, required: true },
  sizes: { type: String, required: false, default: '' },
  media: { type: String, required: true },
  width: { type: String, required: true },
  height: { type: String, required: true },
  format: {
    type: String as PropType<'avif' | 'webp' | 'jpeg'>,
    required: false,
    default: 'avif',
    validator: (value: string) => ['avif', 'webp', 'jpeg'].includes(value),
  },
  fit: {
    type: String as PropType<'cover' | 'contain'>,
    required: false,
    default: 'cover',
    validator: (value: string) => ['cover', 'contain'].includes(value),
  },
});

const image = useImage();

const mainSrc = computed(() => {
  const imgUrl = props.srcset
    ? image(props.srcset, {
        width: Number(props.width),
        height: Number(props.height),
        fit: props.fit,
        format: props.format,
        enlarge: true,
      })
    : generateFakeUrl();

  return imgUrl;
});

function generateFakeUrl() {
  const url = props.srcset;
  const params = new URLSearchParams();
  params.append('f', 'jpeg');
  params.append('s', `${props.width}x${props.height}`);
  params.append('fit', props.fit);
  params.append('url', url);
  return `/_ipx/${params.toString()}`;
}

// /_ipx/fit_cover&f_jpeg&s_488x650/https://Etam.hypernode.io/media/offers/168527756730.jpg

const imageType = computed(() => {
  if (props.format === 'avif') {
    return 'image/avif';
  }

  if (props.format === 'webp') {
    return 'image/webp';
  }

  return 'image/jpeg';
});

// watch(
//   mainSrc,
//   v => {
//     console.log('mainSrc', v);
//   },
//   { immediate: true }
// );
</script>
